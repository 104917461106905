import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TeamItem from "./TeamItem";
import TeamImage from "./TeamImage";

import team1 from "../../img/team1.jpg";
import team2 from "../../img/team2.jpg";
import team3 from "../../img/team3.jpg";
import team4 from "../../img/team4.jpg";
import team5 from "../../img/team5.jpg";

const TeamCarousel = () => {
  return (
    <div className="bg-gray-200 border-b w-full flex flex-col items-center justify-center py-12">
      <p className="text-lg text-gray-600 uppercase font-semibold mb-4">
        Meet our team
      </p>
      <Carousel
        infiniteLoop
        dynamicHeight
        showStatus={false}
        showIndicators={false}
        autoPlay
        transitionTime={1000}
        interval={3000}
        showThumbs={false}
        className="flex h-auto items-center justify-center w-full"
      >
        <TeamItem>
          <TeamImage alt="team1" src={team1} />
        </TeamItem>
        <TeamItem>
          <TeamImage alt="team2" src={team2} />
        </TeamItem>
        <TeamItem>
          <TeamImage alt="team3" src={team3} />
        </TeamItem>
        <TeamItem>
          <TeamImage alt="team4" src={team4} />
        </TeamItem>
        <TeamItem>
          <TeamImage alt="team5" src={team5} />
        </TeamItem>
      </Carousel>
    </div>
  );
};

export default TeamCarousel;
