import React from "react";
import teamall from "../../img/teamall.png";

function InternalMedicine() {
  return (
    <div className="w-full flex justify-center items-center bg-gray-200 bg-sunstate-green">
      <div className="py-24 flex flex-col items-center justify-center text-white font-serif text-center">
        <p className="text-4xl font-serif">
          Our medical practice
          <i className="text-4xl italic"> specializes </i>
          in Internal Medicine.
        </p>
        <p className="text-3xl font-serif">
          Internal Medicine goes
          <i className="text-3xl italic"> beyond </i>
          Primary Care.
        </p>
        <img className="mt-8" alt="teamall" src={teamall} />
      </div>
    </div>
  );
}

export default InternalMedicine;
