import React from "react";
import Layout from "../components/Layout";
import HomeCarousel from "../components/home/HomeCarousel";
import ReviewCarousel from "../components/home/ReviewCarousel";
import Telehealth from "../components/home/Telehealth";
import AboutPractice from "../components/AboutPractice";
import TeamCarousel from "../components/home/TeamCarousel";
import InternalMedicine from "../components/home/InternalMedicine";
import Conditions from "../components/Conditions";
import Insurance from "../components/Insurance";
import Providers from "../components/Providers";
import NewBlogSnippets from "../components/NewBlogSnippets";
import TestimonialSlider from "../components/TestimonialSlider";
import Information from "../components/Information";
import Map from "../components/Map";

import "../App.css";

const Index = () => (
  <Layout>
    <HomeCarousel />
    <ReviewCarousel />
    <Telehealth />
    <AboutPractice />
    <TeamCarousel />
    <InternalMedicine />
    <Conditions />
    <Insurance />
    <Providers />
    <NewBlogSnippets />
    <TestimonialSlider />
    <Information />
    <Map />
  </Layout>
);

export default Index;
