import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import carousel1 from "../../img/carousel1.jpg";
import carousel2 from "../../img/carousel2.jpg";
import carousel3 from "../../img/carousel3.jpg";

const HomeCarousel = () => {
  return (
    <div className="w-full relative lg:h-auto">
      <div className="absolute w-full h-full flex flex-col justify-center items-center text-white z-10">
        <div className="w-1/2 lg:mr-40 lg:mt-40">
          <p className="text-5xl font-serif font-medium text-center lg:text-left">
            Sunstate Medical Associates
          </p>
          <p className="text-4xl text-center lg:text-left">
            Internal Medicine Physicians located in Lake Mary, FL
          </p>
        </div>
      </div>
      <Carousel
        infiniteLoop
        dynamicHeight
        showStatus={false}
        autoPlay
        transitionTime={1000}
        interval={7000}
        showThumbs={false}
      >
        <div>
          <div className="absolute z-40 bg-black h-full w-full opacity-25" />
          <img
            className="h-screen object-cover lg:h-104"
            alt="carousel1"
            src={carousel1}
          />
        </div>
        <div>
          <div className="absolute z-40 bg-black h-full w-full opacity-25" />
          <img
            className="h-screen object-cover lg:h-104"
            alt="carousel2"
            src={carousel2}
          />
        </div>
        <div>
          <div className="absolute z-40 bg-black h-full w-full opacity-25" />
          <img
            className="h-screen object-cover lg:h-104"
            alt="carousel3"
            src={carousel3}
          />
        </div>
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
