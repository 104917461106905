import React from "react";

const TeamItem = ({ children }) => {
  return (
    <div className="w-full h-auto flex flex-col flex-grow bg-gray-200">
      {children}
    </div>
  );
};

export default TeamItem;
