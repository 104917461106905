import React from "react";
import { Link } from "gatsby";

import insurance1 from "../img/insurance1.png";
import insurance2 from "../img/insurance2.png";
import insurance3 from "../img/insurance3.png";
import insurance4 from "../img/insurance4.png";
import insurance5 from "../img/insurance5.png";
import insurance6 from "../img/insurance6.png";
import insurance7 from "../img/insurance7.png";
import insurance8 from "../img/insurance8.png";
import insurance9 from "../img/insurance9.png";
import insurance10 from "../img/insurance10.png";
import insurance11 from "../img/insurance11.png";
import insurance12 from "../img/insurance12.png";

const Insurance = () => {
  return (
    <div className="w-full flex justify-center items-center border-b">
      <div className="w-3/5 flex flex-col lg:flex-row justify-center items-center py-24">
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-start mb-6 lg:mb-0">
          <p className="text-sunstate-primary text-xl font-serif mb-2 text-center lg:text-left">
            We Accept Most Major Insurance Plans!
          </p>
          <p className="text-sunstate-primary text-lg font-serif mb-2 text-center lg:text-left">
            Don't See Your Insurance Listed?
          </p>
          <p className="text-gray-600 text-lg mb-8 text-center lg:text-left">
            That's okay! Contact our office to verify your insurance and discuss
            your options.
          </p>
          <button className="bg-sunstate-primary rounded text-lg text-white font-serif px-10 py-3 tracking-widest shadow-lg">
            Contact Us
          </button>
        </div>
        <div className="w-72 lg:w-1/2 flex flex-col justify-center items-center lg:items-end">
          <div className="w-full flex flex-row flex-wrap justify-center lg:justify-end items-center">
            <img
              className="w-32 border py-4 px-2"
              alt="insurance1"
              src={insurance1}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance2"
              src={insurance2}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance3"
              src={insurance3}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance4"
              src={insurance4}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance5"
              src={insurance5}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance6"
              src={insurance6}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance7"
              src={insurance7}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance8"
              src={insurance8}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance9"
              src={insurance9}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance10"
              src={insurance10}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance11"
              src={insurance11}
            />
            <img
              className="w-32 border py-4 px-2"
              alt="insurance12"
              src={insurance12}
            />
          </div>
          <Link
            to="/contents/resources/insurance-accepted"
            className="text-md text-sunstate-primary mt-2 hover:text-sunstate-blue"
          >
            View full list of companies
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
