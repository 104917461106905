import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReviewItem from "./ReviewItem";

const ReviewCarousel = () => {
  return (
    <div className="h-32 w-full flex items-center justify-center border-b bg-white py-20 z-40">
      <div className="w-full flex items-center justify-center bg-white">
        <Carousel
          infiniteLoop
          showIndicators={false}
          showArrows={false}
          dynamicHeight
          showStatus={false}
          autoPlay
          transitionTime={500}
          interval={5000}
          showThumbs={false}
          className="w-full flex items-center justify-center lg:w-1/2"
        >
          <ReviewItem
            reviewText={`"Dr. Zadeh is always excellent and caring. Love her!"`}
            reviewer="Roxy R."
          />
          <ReviewItem
            reviewText={`"Doctor Zia is very attentive & engaging Doctor."`}
            reviewer="Alireza S."
          />
          <ReviewItem
            reviewText={`"I found Dr. Koruth to be lovely and knowledgeable."`}
            reviewer="Alireza S."
          />
        </Carousel>
      </div>
    </div>
  );
};

export default ReviewCarousel;
