import React from "react";

const Telehealth = () => {
  return (
    <div className="w-full flex justify-center items-center bg-gray-200 border-b">
      <div className="w-1/2 py-16 flex flex-col justify-center items-center">
        <p className="text-3xl text-sunstate-primary text-center font-medium font-serif">
          Effective Monday, March 23, 2020, we began virtual office visitation
          through HIPAA compliant application Tele-Health.
        </p>
        <p className="text-3xl text-sunstate-primary text-center font-medium font-serif mt-12">
          We are encouraging all of our patients to keep their follow up
          appointments or new necessary consultation visits through the
          Tele-Health to minimize exposure.
        </p>
        <button className="bg-sunstate-secondary rounded text-lg text-white px-4 py-2 mt-12">
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Telehealth;
